import { render, staticRenderFns } from "./Shop_PackagesCards.vue?vue&type=template&id=63965a6b&scoped=true&"
import script from "./Shop_PackagesCards.vue?vue&type=script&lang=ts&"
export * from "./Shop_PackagesCards.vue?vue&type=script&lang=ts&"
import style0 from "./Shop_PackagesCards.vue?vue&type=style&index=0&id=63965a6b&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63965a6b",
  null
  
)

export default component.exports