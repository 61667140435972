















































































import {
  computed,
  defineComponent,
  PropType,
} from '@vue/composition-api';
import { ProductContent } from '@fc/app-common/src/plugins/Segment/types';
import { FCCard, FiveStars } from '@fc/angie-ui';
import PricingContainer from '~/components/shop/Shop_PricingContainer.vue';
import FCImage from '~/components/FCImage.vue';
import { fightcampTrackersWithBag } from '~/components/products/content/fightcamp_trackers_with_bag';
import { fightcampTrackers } from '~/components/products/content/fightcamp_trackers';

type TPricePlacement = 'top' | 'bottom';

export default defineComponent({
  name: 'ShopPackageCards',
  props: {
    displayWaysToPay: {
      type: Boolean,
      default: true,
    },
    pricePlacement: {
      type: String as PropType<TPricePlacement>,
      default: 'top',
    },
    ctaText: {
      type: String,
      default: 'Select Package',
    },
    trackingId: {
      type: String,
      required: true,
    },
  },
  components: {
    FCCard,
    FiveStars,
    PricingContainer,
    FCImage,
  },
  setup() {
    // @ts-ignore
    const { $analytics } = useNuxtApp();

    const packages = computed(() => [
      fightcampTrackers,
      fightcampTrackersWithBag,
    ]);

    return {
      packages,
      productClicked: (pkg: ProductContent) => { $analytics.productClicked(pkg); },
    };
  },
});
