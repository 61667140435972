import product from '@/shared/datasets/products';

export const shopifyTrackerData = ({
  pkg,
  quickWrapsItem = null,
  upsellItems = null,
  warrantyItem = null,
}) => {
  // Shopify variant names matter as well since we are using them to find the correct variant here
  const smallTrackers = pkg?.variants?.find((v) => v.name.toLowerCase().includes('small'));
  const largeTrackers = pkg?.variants?.find((v) => v.name.toLowerCase().includes('large'));

  /*
    Order matters in Storyblok for the upsell Items since we are indexing the array
    Shopify variant names matter as well since we are using them to find the correct variant here
  */
  const lightGloves = upsellItems ? upsellItems[0]?.variants?.find((v) => v.name.toLowerCase().includes('12oz')) : null;
  const heavyGloves = upsellItems ? upsellItems[0]?.variants?.find((v) => v.name.toLowerCase().includes('16oz')) : null;
  const kidGloves = upsellItems ? upsellItems[1]?.variants[0] : null;

  // Shopify variant names matter as well since we are using them to find the correct variant here
  const smallQuickWraps = quickWrapsItem ? quickWrapsItem.variants?.find((v) => v.name.toLowerCase().includes('small')) : null;
  const largeQuickWraps = quickWrapsItem ? quickWrapsItem.variants?.find((v) => v.name.toLowerCase().includes('large')) : null;
  return {
    title: pkg?.title,
    id: pkg?.id,
    text: fightcampTrackers.text,
    description: pkg?.description,
    product: {
      price: pkg?.variants[0]?.price,
      affirmPrice: pkg?.variants[0]?.price,
      oldPrice: pkg?.variants[0]?.compareAtPrice,
      months: 24,
      monthly: Math.floor(pkg?.variants[0]?.price / 24),
      path: fightcampTrackers.product.path,
      internalPath: fightcampTrackers.product.internalPath,
    },
    category: fightcampTrackers.category,
    coverImage: fightcampTrackers.coverImage,
    coverImageAlt: fightcampTrackers.coverImageAlt,
    secondaryImage: fightcampTrackers.secondaryImage,
    secondaryImageAlt: fightcampTrackers.secondaryImageAlt,
    includes: fightcampTrackers.includes,
    upsell: [
      {
        name: upsellItems ? upsellItems[0]?.title : null,
        description: upsellItems ? upsellItems[0]?.description : null,
        image: '/v4/fc-additional-items-gloves-001.png',
        imageAlt: 'FightCamp Boxing Gloves',
        productVariants: [
          {
            name: 'Light Gloves (12 oz)',
            itemId: lightGloves?.id,
            description: 'Best for low-to-mid power strikes.',
            product: {
              id: lightGloves?.id,
              price: lightGloves?.price,
              category: 'accessory',
            },
          },
          {
            name: 'Heavy Gloves (16 oz)',
            itemId: heavyGloves?.id,
            description: 'More padding & weight for heavy hitters.',
            product: {
              id: heavyGloves?.id,
              price: heavyGloves?.price,
              category: 'accessory',
            },
          },
          {
            name: 'Kids Gloves (6 oz)',
            itemId: kidGloves?.id,
            description: 'Get the kids involved.',
            product: {
              id: kidGloves?.id,
              price: kidGloves?.price,
              category: 'accessory',
            },
          },
        ],
      },
    ],
    warranty: {
      name: 'Extended Warranty - $19',
      description: 'Yes, upgrade to lengthen your coverage to 36 months. (Highly Recommended)',
      image: product.fightcampTrackersExtWarranty19.img_url,
      imageAlt: 'FightCamp trackers and extended warranty shield icon with check mark at center',
      product: {
        id: warrantyItem ? warrantyItem?.variants[0]?.id : null,
        price: warrantyItem ? warrantyItem?.variants[0]?.price : null,
        category: 'warranty',
      },
      itemId: warrantyItem ? warrantyItem?.variants[0]?.id : null,
    },
    bonusOptions: fightcampTrackers.bonusOptions,
    selects: [{
      name: 'Quick Wrap Size',
      key: 'pair1',
      htc: 'How To Choose the Size',
      hint: 'Choose your size',
      value: smallQuickWraps?.id,
      options: [{
        key: smallQuickWraps?.id,
        name: 'Small',
        htc: 'Ideal for small to medium size hands.',
      },
      {
        key: largeQuickWraps?.id,
        name: 'Large',
        htc: 'Ideal for larger hands.',
      },
      ],
    }],
    variants: [
      {
        keys: {
          pair1: smallQuickWraps?.id,
        },
        sku: smallTrackers?.id,
      },
      {
        keys: {
          pair1: largeQuickWraps?.id,
        },
        sku: largeTrackers?.id,
      },
    ],
  };
};

export const fightcampTrackers = {
  title: 'Trackers',
  id: 'fightcamp_trackers',
  text: 'Our essentials package',
  description: 'For those who have access to a boxing bag. Get the essentials you need to start your FightCamp journey. Track your progress, compete against others, and access on-demand workouts led by expert trainers.',
  product: product.fightcampTrackers,
  category: 'package',
  coverImage: '/v4/fc-fightcamp-base-002.png',
  coverImageAlt: 'Fightcamp quick wraps and trackers',
  secondaryImage: '/v4/fc-fightcamp-base-man-shadowboxing-001.jpeg',
  secondaryImageAlt: 'Man with fightcamp base shadowboxing',
  includes: {
    basePackage:
      [
        {
          name: 'Trackers',
          image: {
            src: 'v4/fc-product-includes-trackers-002.png',
            alt: 'FightCamp Trackers',
          },
          carouselImages: [
            {
              src: 'https://a.storyblok.com/f/152150/4216x3168/75f1824a85/fc-package-woman-putting-wraps-on-003.png',
              alt: 'Woman putting trackers into FightCamp quick wraps',
            },
            {
              src: 'https://a.storyblok.com/f/152150/4216x3169/9fd7d6f0fc/fc-package-tracker-wrap-002.png',
              alt: 'FightCamp trackers',
            },
          ],
          description: 'Our cutting-edge sensors unlock all of the most powerful FightCamp features. Used by many of the top Fighters in the world, they track and display punch volume, speed and Output in real time. Watch your punch count climb & compete against others all while having fun doing it.',
        },
        {
          name: 'Quick Wraps',
          image: {
            src: 'v4/fc-product-includes-wraps-001.png',
            alt: 'FightCamp Quick Wraps',
          },
          description: 'Our premium training gloves are 100% hand-made, ultra comfortable, and super durable. Small gloves are best for low-to-mid power strikes. Large gloves offer more padding & add weight for heavy hitters.',
        },
      ],
    extras: [],
  },
  upsell: [
    {
      name: 'FightCamp Gloves',
      description: '100% hand-made, ultra comfortable, and super durable. ',
      image: '/v4/fc-additional-items-gloves-001.png',
      imageAlt: 'FightCamp Boxing Gloves',
      productVariants: [
        {
          name: 'Light Gloves (12 oz)',
          itemId: 'customGloves_V2_S',
          description: 'Best for low-to-mid power strikes.',
          product: product.glovesv2,
        },
        {
          name: 'Heavy Gloves (16 oz)',
          itemId: 'customGloves_V2_L',
          description: 'More padding & weight for heavy hitters.',
          product: product.glovesv2,
        },
        {
          name: 'Kids Gloves (6 oz)',
          itemId: 'customGloves_V2_K',
          description: 'Get the kids involved.',
          product: product.kidsglovesv2,
        },
      ],
    },
  ],
  warranty: {
    name: 'Extended Warranty - $19',
    description: 'Yes, upgrade to lengthen your coverage to 36 months. (Highly Recommended)',
    image: product.fightcampTrackersExtWarranty19.img_url,
    imageAlt: 'FightCamp trackers and extended warranty shield icon with check mark at center',
    product: product.fightcampTrackersExtWarranty19,
    itemId: product.fightcampTrackersExtWarranty19.id,
  },
  bonusOptions: [],
  selects: [{
    name: 'Quick Wraps Size',
    key: 'pair1',
    htc: 'How To Choose the Size',
    hint: 'Choose your size',
    value: 's',
    options: [{
      key: 's',
      name: 'Small',
      htc: 'Ideal for small to medium size hands.',
    },
    {
      key: 'l',
      name: 'Large',
      htc: 'Ideal for larger hands.',
    },
    ],
  }],
  variants: [{
    keys: {
      pair1: 's',
    },
    sku: 'trackers_S',
  },
  {
    keys: {
      pair1: 'l',
    },
    sku: 'trackers_L',
  },
  ],
};
