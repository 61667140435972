import product from '@/shared/datasets/products';

export const shopifyTrackerBagData = ({
  pkg,
  quickWrapsItem = null,
  upsellItems = null,
  warrantyItem = null,
}) => {
  // Shopify variant names matter as well since we are using them to find the correct variant here
  const smallTrackersBag = pkg?.variants?.find((v) => v.name.toLowerCase().includes('small'));
  const largeTrackersBag = pkg?.variants?.find((v) => v.name.toLowerCase().includes('large'));

  /*
      Order matters in Storyblok for the upsell Items since we are indexing the array
      Shopify variant names matter as well since we are using them to find the correct variant here
    */
  const lightGloves = upsellItems ? upsellItems[0]?.variants?.find((v) => v.name.toLowerCase().includes('12oz')) : null;
  const heavyGloves = upsellItems ? upsellItems[0]?.variants?.find((v) => v.name.toLowerCase().includes('16oz')) : null;
  const kidGloves = upsellItems ? upsellItems[1]?.variants[0] : null;
  const bagRing = upsellItems ? upsellItems[2] : null;

  // Shopify variant names matter as well since we are using them to find the correct variant here
  const smallQuickWraps = quickWrapsItem ? quickWrapsItem?.variants?.find((v) => v.name.toLowerCase().includes('small')) : null;
  const largeQuickWraps = quickWrapsItem ? quickWrapsItem?.variants?.find((v) => v.name.toLowerCase().includes('large')) : null;

  return {
    title: pkg?.title,
    id: pkg?.id,
    text: fightcampTrackersWithBag.text,
    description: pkg?.description,
    product: {
      price: pkg?.variants[0]?.price,
      affirmPrice: pkg?.variants[0]?.price,
      oldPrice: pkg?.variants[0]?.compareAtPrice,
      months: 24,
      monthly: Math.floor(pkg?.variants[0]?.price / 24),
      path: fightcampTrackersWithBag.product.path,
      internalPath: fightcampTrackersWithBag.product.internalPath,
    },
    category: fightcampTrackersWithBag.category,
    coverImage: fightcampTrackersWithBag.coverImage,
    coverImageAlt: fightcampTrackersWithBag.coverImageAlt,
    secondaryImage: fightcampTrackersWithBag.secondaryImage,
    secondaryImageAlt: fightcampTrackersWithBag.secondaryImageAlt,
    includes: fightcampTrackersWithBag.includes,
    // Order matters in Storyblok for the upsell Items!!
    upsell: [
      {
        name: upsellItems ? upsellItems[0]?.title : null,
        description: upsellItems ? upsellItems[0]?.description : null,
        image: '/v4/fc-additional-items-gloves-001.png',
        imageAlt: 'FightCamp Boxing Gloves',
        productVariants: [
          {
            name: 'Light Gloves (12 oz)',
            itemId: lightGloves?.id,
            description: 'Best for low-to-mid power strikes.',
            product: {
              id: lightGloves?.id,
              price: lightGloves?.price,
              category: 'accessory',
            },
          },
          {
            name: 'Heavy Gloves (16 oz)',
            itemId: heavyGloves?.id,
            description: 'More padding & weight for heavy hitters.',
            product: {
              id: heavyGloves?.id,
              price: heavyGloves?.price,
              category: 'accessory',
            },
          },
          {
            name: 'Kids Gloves (6 oz)',
            itemId: kidGloves?.id,
            description: 'Get the kids involved.',
            product: {
              id: kidGloves?.id,
              price: kidGloves?.price,
              category: 'accessory',
            },
          },
        ],
      },
      {
        name: bagRing?.title,
        description: bagRing?.variants[0]?.description,
        additionalInformation: 'Goes under your bag. No more sliding or adjusting the bag mid-workout.',
        image: '/v4/fc-additional-items-bag-ring-001.png',
        imageAlt: 'FightCamp Bag Ring',
        product: {
          id: bagRing?.variants[0]?.id,
          price: bagRing?.variants[0]?.price,
          category: 'accessory',
        },
        itemId: bagRing?.variants[0]?.id,
      },
    ],
    warranty: {
      name: 'Extended Warranty - $79',
      description: 'Yes, upgrade to lengthen your coverage to 36 months. (Highly Recommended)',
      image: product.fightcampTrackersBagExtWarranty79.img_url,
      imageAlt: 'FightCamp trackers with bag and extended warranty shield icon with check mark at center',
      product: {
        id: warrantyItem ? warrantyItem?.variants[0]?.id : null,
        price: warrantyItem ? warrantyItem?.variants[0]?.price : null,
        category: 'warranty',
      },
      itemId: warrantyItem ? warrantyItem?.variants[0]?.id : null,
    },
    bonusOptions: fightcampTrackersWithBag.bonusOptions,
    selects: [{
      name: 'Quick Wrap Size',
      key: 'pair1',
      htc: 'How To Choose the Size',
      hint: 'Choose your size',
      value: smallQuickWraps?.id,
      options: [{
        key: smallQuickWraps?.id,
        name: 'Small',
        htc: 'Ideal for small to medium size hands.',
      },
      {
        key: largeQuickWraps?.id,
        name: 'Large',
        htc: 'Ideal for larger hands.',
      },
      ],
    }],
    variants: [{
      keys: {
        pair1: smallQuickWraps?.id,
      },
      sku: smallTrackersBag?.id,
    },
    {
      keys: {
        pair1: largeQuickWraps?.id,
      },
      sku: largeTrackersBag?.id,
    },
    ],
  };
};

export const fightcampTrackersWithBag = {
  title: 'Trackers + Bag',
  id: 'fightcamp_trackers_bag',
  text: 'All the essentials, plus the bag',
  description: 'For those who want a boxing bag. Get the essentials you need to start your FightCamp journey, plus the best free-standing bag on the market. Track your progress, compete against others, and access on-demand workouts.',
  product: product.fightcampTrackersBag,
  category: 'package',
  coverImage: '/v4/fc-fightcamp-base-with-bag-002.png',
  coverImageAlt: 'FightCamp with bag and gloves',
  secondaryImage: '/v4/fc-fightcamp-base-w-bag-woman-boxing-002.jpg',
  secondaryImageAlt: 'Woman with fightcamp base and bag punching and boxing',
  includes: [
    {
      name: 'Trackers',
      image: {
        src: 'v4/fc-product-includes-trackers-002.png',
        alt: 'FightCamp Trackers',
      },
      carouselImages: [
        {
          src: 'https://a.storyblok.com/f/152150/4216x3168/75f1824a85/fc-package-woman-putting-wraps-on-003.png',
          alt: 'Woman putting trackers into FightCamp quick wraps',
        },
        {
          src: 'https://a.storyblok.com/f/152150/4216x3169/9fd7d6f0fc/fc-package-tracker-wrap-002.png',
          alt: 'FightCamp trackers',
        },
      ],
      description: 'Our cutting-edge sensors unlock all of the most powerful FightCamp features. Used by many of the top Fighters in the world, they track and display punch volume, speed and Output in real time. Watch your punch count climb & compete against others all while having fun doing it.',
    },
    {
      name: 'Quick Wraps',
      image: {
        src: 'v4/fc-product-includes-wraps-001.png',
        alt: 'FightCamp Quick Wraps',
      },
      description: 'Our premium training gloves are 100% hand-made, ultra comfortable, and super durable. Small gloves are best for low-to-mid power strikes. Large gloves offer more padding & add weight for heavy hitters.',
    },
    {
      name: 'Boxing Bag',
      image: {
        src: 'v4/fc-product-includes-bag-005.png',
        alt: 'FightCamp Bag and Base',
      },
      carouselImages: [
        {
          src: 'https://a.storyblok.com/f/152150/4000x2667/00906279ca/fc-package-bag-in-livingroom-001.png',
          alt: 'FightCamp tracker and bag package in living room',
        },
        {
          src: 'https://a.storyblok.com/f/152150/3000x2001/9600c38d4a/fc-package-man-boxing-bag-001.png',
          alt: 'Man boxing with FightCamp bag in living room',
        },
      ],
      description: 'The FightCamp bag is the first free-standing bag that allows you to go all-out without unforgiving bag displacements. It features a two-part stem designed for maximum shock absorption and fits any 2’x2’ space and stands 5ft 7in tall. When filled with water, it weighs 250 lbs or with sand, weighs 350 lbs.',
    },
  ],
  upsell: [
    {
      name: 'FightCamp Gloves',
      description: '100% hand-made, ultra comfortable, and super durable.',
      image: '/v4/fc-additional-items-gloves-001.png',
      imageAlt: 'FightCamp Boxing Gloves',
      productVariants: [
        {
          name: 'Light Gloves (12 oz)',
          itemId: 'customGloves_V2_S',
          description: 'Best for low-to-mid power strikes.',
          product: product.glovesv2,
        },
        {
          name: 'Heavy Gloves (16 oz)',
          itemId: 'customGloves_V2_L',
          description: 'More padding & weight for heavy hitters.',
          product: product.glovesv2,
        },
        {
          name: 'Kids Gloves (6 oz)',
          itemId: 'customGloves_V2_K',
          description: 'Get the kids involved.',
          product: product.kidsglovesv2,
        },
      ],
    },
    {
      name: 'FightCamp Bag Ring',
      description: 'Size: 27in in diameter',
      additionalInformation: 'Goes under your bag. No more sliding or adjusting the bag mid-workout.',
      image: '/v4/fc-additional-items-bag-ring-001.png',
      imageAlt: 'FightCamp Bag Ring',
      product: product.fightcampBagRingBpi,
      itemId: product.fightcampBagRingBpi.id,
    },
  ],
  warranty: {
    name: 'Extended Warranty - $79',
    description: 'Yes, upgrade to lengthen your coverage to 36 months. (Highly Recommended)',
    image: product.fightcampTrackersBagExtWarranty79.img_url,
    imageAlt: 'FightCamp trackers with bag and extended warranty shield icon with check mark at center',
    product: product.fightcampTrackersBagExtWarranty79,
    itemId: product.fightcampTrackersBagExtWarranty79.id,
  },
  bonusOptions: [],
  selects: [{
    name: 'Quick Wrap Size',
    key: 'pair1',
    htc: 'How To Choose the Size',
    hint: 'Choose your size',
    value: 's',
    options: [{
      key: 's',
      name: 'Small',
      htc: 'Ideal for small to medium size hands.',
    },
    {
      key: 'l',
      name: 'Large',
      htc: 'Ideal for larger hands.',
    },
    ],
  }],
  variants: [{
    keys: {
      pair1: 's',
    },
    sku: 'trackers_with_bag_S',
  },
  {
    keys: {
      pair1: 'l',
    },
    sku: 'trackers_with_bag_L',
  },
  ],
};
